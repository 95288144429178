<template>
  <div>
    <apexchart
      ref="chart"
      width="100%"
      type="line"
      :options="options"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";

Vue.component("apexchart", VueApexCharts);
export default {
  name: "ChartComponents",
  props: ["data", "name"],
  data() {
    return {
      items: {},
      options: {
        chart: {
          id: "vuechart-example",
        },
        xaxis: {
          categories: [],
        },
        stroke: {
          show: true,
          curve: "smooth",
          lineCap: "butt",
          colors: undefined,
          width: 2,
          dashArray: 0,
        },
      },
      series: [
        {
          name: "series-1",
          data: [],
        },
      ],
    };
  },
  mounted() {
    if (this.data) {
      this.items = this.data;
      this.convertChart();
    }
  },
  methods: {
    convertChart() {
      let data = this.items;

      this.options.chart.id = this.name;

      this.options.xaxis.categories = data.fields.map(
        (obj) => Object.values(obj)[0]
      );
      this.options.yaxis = {
        labels: {
          formatter: function (value) {
            var formatter = new Intl.NumberFormat("it-IT");
            return formatter.format(value);
          },
        },
      };

      if (data.item.length > 0) {
        let items = data.item.filter((item) => item.TYPE !== "Tổng số");
        this.series = items.map((item) => {
          const name = item.TYPE;
          const dates = Object.keys(item).filter((key) => key !== "TYPE");
          const values = dates.map((date) =>
            parseInt(item[date].replace(/,/g, ""))
          );

          return {
            name,
            data: values,
          };
        });
      }
      this.options.xaxis.categories.splice(0, 1);
      this.$refs.chart.refresh();
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-md-12">
      <div>
        <b-card-group deck>
          <b-card header-tag="header" footer-tag="footer">
            <template v-slot:header>
              <h6 class="mb-0">Dashboard Marketing</h6>
            </template>
            <b-row>
              <b-col>
                <b-form-group label="Chỉ số">
                  <b-form-select
                      v-model="input.source_type"
                      :options="sources"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Loại chỉ số">
                  <b-form-select
                      v-model="input.type"
                      :options="types"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Loại thời gian (*)">
                  <b-form-select
                    v-model="input.kindOfTime"
                    :options="options.kindOfTime"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col v-if="!this.show.daily">
                <b-form-group label="Năm">
                  <b-form-select
                    v-model="input.year"
                    :options="years"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col v-if="this.show.daily">
                <b-form-group label="Ngày bắt đầu(*)">
                  <b-form-input
                    :id="`type-date`"
                    :type="`date`"
                    v-model="input.from"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col v-if="this.show.daily">
                <b-form-group label="Ngày kết thúc(*)">
                  <b-form-input
                    :id="`type-date`"
                    :type="`date`"
                    v-model="input.to"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col v-if="this.show.daily"></b-col>
              <b-col v-if="this.show.monthly">
                <b-form-group label="Tháng">
                  <b-form-select
                    v-model="input.month"
                    :options="options.month"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col v-if="this.show.monthly"> </b-col>
              <b-col v-if="this.show.quarterly">
                <b-form-group label="Quý">
                  <b-form-select
                    v-model="input.quarter"
                    :options="options.quarter"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col v-if="this.show.quarterly"> </b-col>
              <b-col
                v-if="
                  !this.show.daily && !this.show.monthly && !this.show.quarterly
                "
              ></b-col>
              <b-col
                v-if="
                  !this.show.daily && !this.show.monthly && !this.show.quarterly
                "
              ></b-col>
            </b-row>
            <b-row>
              <b-col class="text-right">
                <b-button
                  class="mr-2"
                  variant="outline-primary"
                  @click="listAll()"
                  >Tìm kiếm</b-button
                >
                <b-button v-if="items && (items.GMV || items.REV)" variant="primary" @click="exportData"><i class="fa fa-download"></i>Xuất Excel</b-button>
              </b-col>
            </b-row>
            <hr />

            <b-row v-if="items && (items.GMV && items.REV)">
              <b-col class="col-lg-6">
                <marketing-chart v-if="items.GMV" :data="items.GMV" name="gmv"></marketing-chart>
              </b-col>
              <b-col class="col-lg-6">
                <marketing-chart v-if="items.REV" :data="items.REV" name="rev"></marketing-chart>
              </b-col>
            </b-row>

            <b-row v-else>
              <b-col class="col-lg-2"></b-col>
              <b-col class="col-lg-8">
                <marketing-chart v-if="items && items.GMV" :data="items.GMV" name="gmv"></marketing-chart>
                <marketing-chart v-if="items &&  items.REV" :data="items.REV" name="rev"></marketing-chart>
              </b-col>
              <b-col class="col-lg-2"></b-col>
            </b-row>
            <b-row v-bind:key="index" v-for="(value, index) in items">
              <p><strong>{{ index }}</strong></p>
              <b-table
                :fields="value.fields"
                :items="value.item"
                details-td-class="datatable-cell"
                hover
                table-class="datatable-table"
                tbody-tr-class="datatable-row"
                thead-class="datatable-head"
                thead-tr-class="datatable-row"
              >
              </b-table>
            </b-row>

          </b-card>
        </b-card-group>
      </div>
    </div>
  </div>
</template>
<style>
.datatable-table .datatable-row:last-child {
  font-weight: bold;
}
</style>
<script>
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";
import MarketingChart from "./ChartComponents.vue";

const CmsRepository = RepositoryFactory.get("cms");

export default {
  mixins: [Common],
  components: { MarketingChart },
  data() {
    return {
      input: {
        type: null,
        source_type: "REV",
        year: new Date().getFullYear(),
        kindOfTime: "YEARLY",
        from: this.getYesterday(),
        to: this.getCurrentDay(),
        month: 1,
        quarter: 1,
      },
      options: {
        kindOfTime: [
          { value: "DAILY", text: "Ngày" },
          { value: "MONTHLY", text: "Tháng" },
          { value: "QUARTERLY", text: "Quý" },
          { value: "YEARLY", text: "Năm" },
        ],
        month: [
          { value: "1", text: "Tháng 1" },
          { value: "2", text: "Tháng 2" },
          { value: "3", text: "Tháng 3" },
          { value: "4", text: "Tháng 4" },
          { value: "5", text: "Tháng 5" },
          { value: "6", text: "Tháng 6" },
          { value: "7", text: "Tháng 7" },
          { value: "8", text: "Tháng 8" },
          { value: "9", text: "Tháng 9" },
          { value: "10", text: "Tháng 10" },
          { value: "11", text: "Tháng 11" },
          { value: "12", text: "Tháng 12" },
        ],
        quarter: [
          { value: "1", text: "Quý 1" },
          { value: "2", text: "Quý 2" },
          { value: "3", text: "Quý 3" },
          { value: "4", text: "Quý 4" },
        ],
      },
      types: [
        { value: null, text: "Tất cả" },
      ],
      sources: [
        { value: null, text: "Tất cả" },
        { value: "GMV", text: "GMV" },
        { value: "REV", text: "REV" },
      ],
      items: null,
      fields: [],
      show: {
        daily: false,
        monthly: false,
        quarterly: false,
      },
    };
  },
  created() {
    this.listServices()
  },
  mounted() {},
  methods: {
    listServices() {
      let services = [
          { value: null, text: "Tất cả" },
      ];

      this.$bus.$emit("show-loading", true);
      let self = this;
      return CmsRepository.listServices().then(function(response) {
        self.$bus.$emit("show-loading", false);
        let data = response.data;

        if (data.data.error_code !== 0) {
          self.notifyAlert("error", data.data.message ? data.data.message : 'Có lỗi xảy ra, vui lòng thử lại sau.');
          return;
        }

        let listServices = data.data.data;
        self.types = services.concat(listServices);
      }).catch(function (error) {
        self.notifyAlert("error", error);
        self.$bus.$emit("show-loading", false);
      });
    },
    listAll() {
      let params = this.getParams(this.input.kindOfTime);
      if (!params) {
        return false;
      }

      params.type = this.input.type;
      params.source_type = this.input.source_type;
      this.items = null;
      this.fields = [];
      this.$bus.$emit("show-loading", true);
      CmsRepository.listDashboard(params)
        .then((response) => {
          if (response.data.error_code) {
            this.notifyAlert("warn", response.data.message);
          } else {
            this.items = response.data.data.data;
            this.notifyAlert("success", "Lấy dữ liệu thành công");
          }
          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          this.$bus.$emit("show-loading", false);
        });
    },
    exportData() {
      let params = this.getParams(this.input.kindOfTime);
      if (!params) {
        return false;
      }
      params.type = this.input.type;
      params.source_type = this.input.source_type;
      this.$bus.$emit("show-loading", true);

      CmsRepository.exportData(params)
        .then((response) => {
          if (response.data.error_code) {
            this.$bus.$emit("show-loading", false);
            this.notifyAlert("warn", response.data.message);
            return;
          }

          this.$bus.$emit("show-loading", false);
          window.open(response.data.data, "_blank");
          this.notifyAlert("success", "Lấy dữ liệu thành công");
          return;
        })
        .catch(() => {
          this.$bus.$emit("show-loading", false);
        });
    },
    getParams(kindOfTime) {
      if (kindOfTime === "DAILY") {
        const dateFrom = new Date(this.input.from);
        const dateTo = new Date(this.input.to);
        const timeDiff = Math.abs(dateTo.getTime() - dateFrom.getTime());
        const dayDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
        if (dayDiff > 30) {
          this.notifyAlert(
            "warn",
            "Phạm vi tìm kiếm không được vượt quá 30 ngày"
          );
          return false;
        }

        return {
          kind_of_time: this.input.kindOfTime,
          from: this.input.from,
          to: this.input.to,
        };
      } else if (kindOfTime === "MONTHLY") {
        return {
          year: this.input.year,
          kind_of_time: this.input.kindOfTime,
          month: this.input.month,
        };
      } else if (kindOfTime === "QUARTERLY") {
        return {
          year: this.input.year,
          kind_of_time: this.input.kindOfTime,
          quarter: this.input.quarter,
        };
      } else {
        return {
          year: this.input.year,
          kind_of_time: this.input.kindOfTime,
        };
      }
    },
  },
  watch: {
    "input.kindOfTime"() {
      this.show.daily = false;
      this.show.monthly = false;
      this.show.quarterly = false;
      if (this.input.kindOfTime == "DAILY") {
        this.show.daily = true;
      } else if (this.input.kindOfTime == "MONTHLY") {
        this.show.monthly = true;
      } else if (this.input.kindOfTime == "QUARTERLY") {
        this.show.quarterly = true;
      }
    },
  },
  computed: {
    years() {
      const year = new Date().getFullYear();
      return Array.from(
        { length: year - 2019 },
        (value, index) => 2020 + index
      );
    },
  },
};
</script>
